<template>
  <b-container fluid>
    <b-row class="pl-0 pr-0">
      <b-col cols="12" class="pl-0 pr-0">
        <b-card class="mb-0" no-body>
          <!-- <template #footer>

          </template> -->
          <b-container>
            <b-row class="px-3" align-h="between" align-v="center">
              <b-col sm="12" md="2" lg="2" class="px-0">
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5.5em; height: 5.5em"
                      :variant="'light-success'"
                    >
                      <feather-icon size="28" :icon="'ThumbsUpIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 style="display: inline" class="font-weight-bolder mb-0">
                      {{ statistics.inbound_success }}

                      <h5
                        :style="{
                          marginLeft: '2px',
                          display: 'inline',
                          opacity: 0.5,
                        }"
                      >
                        ({{ statistics.inbound_avg_time }})
                      </h5>
                    </h2>
                    <h4 class="font-weight-bolder mb-0"></h4>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_answered") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="px-0">
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5.5em; height: 5.5em"
                      :variant="'light-danger'"
                    >
                      <feather-icon size="28" :icon="'ThumbsDownIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 class="font-weight-bolder mb-0">
                      {{ statistics.inbound_failed }}
                    </h2>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_abandoned") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="px-0">
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5.5em; height: 5.5em"
                      :variant="'light-primary'"
                    >
                      <feather-icon
                        size="28"
                        :icon="60 > 50 ? 'TrendingUpIcon' : 'TrendingDownIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 class="font-weight-bolder mb-0">
                      {{ `%${statistics.productivity}` }}
                    </h2>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_productivity") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="px-0">
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5.5em; height: 5.5em"
                      :variant="'light-success'"
                    >
                      <feather-icon size="28" :icon="'CheckIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 style="display: inline" class="font-weight-bolder mb-0">
                      {{ statistics.outbound_success }}
                      <h5
                        :style="{
                          marginLeft: '2px',
                          display: 'inline',
                          opacity: 0.5,
                        }"
                      >
                        ({{ statistics.outbound_avg_time }})
                      </h5>
                    </h2>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_successfullCall") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="px-0">
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5.5em; height: 5.5em"
                      :variant="'light-danger'"
                    >
                      <feather-icon size="28" :icon="'XIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 class="font-weight-bolder mb-0">
                      {{ statistics.outbound_failed }}
                    </h2>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_unsuccessfullCall") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pl-0 pr-0">
      <b-col sm="12" md="6" lg="6" class="pr-50 pl-0">
        <b-card style="height: 39.5vh; overflow-y: auto" class="mt-1 mb-1">
          <b-card-title class="pb-1">
            <feather-icon
              class="float-right cursor-pointer"
              icon="MoreVerticalIcon"
            ></feather-icon>
          </b-card-title>

          <!-- FINISH CODE -->
          <b-table small :fields="fc_fields" :items="fc_data" responsive="sm">
            <!-- A custom formatted column -->
            <template #cell(rate)="data">
              <b-progress
                show-value
                :value="(data.value * 100) / total_finish_code_counts"
                :max="100"
                :variant="
                  parseInt((data.value / total_finish_code_counts) * 100) > 40
                    ? 'success'
                    : 'warning'
                "
                striped
              />
            </template>

            <template #cell(category)="data">
              <center>
                <b-badge pill class="w-50" :variant="'light-secondary'">
                  {{ data.value }}
                </b-badge>
              </center>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- STATUS -->

      <b-col sm="12" md="6" lg="6" class="pl-50 pr-0">
        <b-card style="height: 39.5vh; overflow-y: auto" class="mt-1 mb-1">
          <!-- <template #header>
            <b-row>
              <b-button
                size="sm"
                class="ml-50"
                :variant="
                  selectedButton != 'status'
                    ? 'outline-primary'
                    : 'gradient-primary'
                "
                pill
                @click="selectedButton = 'status'"
              >
                {{ lang("t_status") }}
              </b-button>
              <b-button
                size="sm"
                class="ml-50"
                :variant="
                  selectedButton != 'target'
                    ? 'outline-primary'
                    : 'gradient-primary'
                "
                pill
                @click="selectedButton = 'target'"
              >
                {{ lang("t_target") }}
              </b-button>
            </b-row>
          </template> -->
          <b-card-title class="pb-1">
            <div v-if="selectedButton == 'status'">
              <b-badge class="float-left mr-50" variant="light-success">
                <span>
                  <strong>{{ lang("t_remainingBreakTime") }}:</strong>
                  {{ statistics.remaining_break_time }}
                </span>
              </b-badge>
              <b-badge class="float-left" variant="light-warning">
                <span>
                  <strong>{{ lang("t_usedBreakTime") }}:</strong>
                  {{ statistics.break_time }}
                </span>
              </b-badge>
            </div>
            <div v-else>
              <b-badge
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_dailyTarget')"
                class="float-left mr-50"
                variant="light-primary"
              >
                <span>
                  <strong>D.T:</strong>
                  {{ collection_statistics.daily_collection_goal }}
                </span>
              </b-badge>

              <b-badge
                v-b-tooltip.hover.v-warning
                v-b-tooltip.hover.bottom="lang('t_remaining')"
                class="float-left mr-50"
                variant="light-warning"
              >
                <span>
                  <strong>R.T:</strong>
                  {{ collection_statistics.remaining_collection }}
                </span>
              </b-badge>
              <b-badge
                v-b-tooltip.hover.v-success
                v-b-tooltip.hover.bottom="lang('t_compilationRate')"
                class="float-left"
                variant="light-success"
              >
                <span>
                  <strong>C.R:</strong>
                  %{{ collection_statistics.collection_rate }}
                </span>
              </b-badge>
            </div>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown float-right"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="text-body cursor-pointer float-right"
                />
              </template>
              <b-dropdown-item
                v-if="selectedButton == 'target'"
                @click="selectedButton = 'status'"
              >
                {{ lang("t_status") }}
              </b-dropdown-item>
              <b-dropdown-item v-else @click="selectedButton = 'target'">
                {{ lang("t_target") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-title>
          <b-card no-body v-if="selectedButton == 'status'">
            <!-- <template #header>
              <b-row style="width: 100%" align-h="end">
                <b-badge class="float-right mr-50" variant="success">
                  <span>
                    <strong>{{ lang("t_remainingBreakTime") }}:</strong>
                    {{ statistics.remaining_break_time }}
                  </span>
                </b-badge>
                <b-badge class="float-right" variant="warning">
                  <span>
                    <strong>{{ lang("t_breakTime") }}:</strong>
                    {{ statistics.break_time }}
                  </span>
                </b-badge>
              </b-row>
            </template> -->
            <!-- <b-card-text>
              <chartjs-component-bar-chart
                style="height: 25.5vh"
                :data="chartjsData.data"
                :options="chartjsData.options"
                :key="custom_key"
                ref="realtimeChart"
              />
            </b-card-text> -->
            <b-table small :fields="st_fields" :items="st_data" responsive>
              <template #cell(category)="data">
                <center>
                  <b-badge pill class="w-50" :variant="'light-secondary'">
                    {{ data.value }}
                  </b-badge>
                </center>
              </template>
              <template #cell(time)="data">
                {{ toHHMMSS(data.value) }}
              </template>
            </b-table>
          </b-card>
          <b-card style="height: 88%" v-else no-body>
            <b-card-body class="my-0 py-0">
              <vue-apex-charts
                class="my-0 py-0"
                width="100%"
                height="100%"
                type="line"
                :options="revenueComparisonLine.chartOptions"
                :series="revenueComparisonLine.series"
              />
            </b-card-body>
          </b-card>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pl-0 pr-0">
      <b-col cols="9" class="pt-0 pb-0 mb-0 mt-0 pr-50 pl-0">
        <b-card style="height: 30.5vh; margin: 0">
          <!-- <template #header>
            <b-row>
              <h4 class="mb-0 ml-1">{{ lang("t_callHistory") }}</h4>
            </b-row>
          </template> -->

          <b-table
            style="max-height: 25vh"
            borderless
            hover
            :fields="ch_fields"
            :items="ch_data"
            responsive
            small
            :sortBy="'insert_date'"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(queuename)="data">
              {{
                queues.find((e) => e.internal_name == data.value)
                  ? queues.find((e) => e.internal_name == data.value)
                      .display_name
                  : data.value
              }}
            </template>
            <template #cell(contact_number)="data">
              <a href="#" @click="start_call(data.value)">
                {{ GetRemoteNumber(data.value) }}</a
              >
            </template>
            <template #cell(RecordingFile)="data">
              <center>
                <b-button
                  class="ml-25 px-50 py-25"
                  v-if="data.item.RecordingFile != ''"
                  size="sm"
                  @click="openAudio(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="PlayIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(note)="data">
              <center>
                <b-button
                  class="ml-25 px-50 py-25"
                  size="sm"
                  v-if="data.value.length > 0"
                  variant="outline-primary"
                  @click="openNote(data.value)"
                >
                  Not
                </b-button>
              </center>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="3" class="pl-50 pr-0">
        <b-card style="height: 30.5vh; margin: 0">
          <div class="px-1" style="max-height: 27vh; overflow-y: auto">
            <b-row
              class="py-25"
              v-for="(item, i) in waiting_on_queue_data"
              :key="i"
            >
              <b-col class="pl-0" cols="3">
                <!-- <b-avatar
                  rounded="sm"
                  :text=""
                  variant="light-primary"
                /> -->
                <b-avatar
                  rounded="sm"
                  :variant="set_waiting_in_queue_color(item.call_count)"
                >
                  {{ item.call_count }}
                </b-avatar>
              </b-col>
              <b-col class="pl-0" cols="9">
                <span
                  class="pt-50"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    queues.find((e) => e.internal_name == item.queue)
                      ? queues.find((e) => e.internal_name == item.queue)
                          .display_name
                      : item.queue
                  "
                  style="
                    display: inline-block;
                    width: 8vw;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  "
                >
                  {{
                    queues.find((e) => e.internal_name == item.queue)
                      ? queues.find((e) => e.internal_name == item.queue)
                          .display_name
                      : item.queue
                  }}
                </span>
              </b-col>
            </b-row>
          </div>
          <!-- <template #header>
            <b-row>
              <h4 class="mb-0 ml-1">{{ lang("t_waitingInQueue") }}</h4>
            </b-row>
          </template> -->
          <!-- <b-table
            style="max-height: 25vh"
            borderless
            hover
            :fields="wq_fields"
            :items="waiting_on_queue_data"
            responsive
            small
          >
            <template #cell(call_count)="data">
              <b-badge
                pill
                style="width: 4em"
                :variant="
                  data.value < 3
                    ? 'success'
                    : data.value < 5
                    ? 'warning'
                    : 'danger'
                "
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(queue)="data">
              {{
                queues.find((e) => e.internal_name == data.value).display_name
              }}
            </template>
          </b-table> -->
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BListGroup,
  BListGroupItem,
  BCardSubTitle,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgressBar,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BCardFooter,
  VBTooltip,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import "animate.css";
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";

function toHHMMSS(sec_num) {
  try {
    return new Date(sec_num * 1000).toISOString().substr(11, 8);
  } catch (error) {
    return sec_num;
  }
}
const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  components: {
    BContainer,
    BProgressBar,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ChartjsComponentBarChart,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardHorizontal,
    StatisticCardVertical,
    BCardCode,
    VueApexCharts,
    BTable,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    VBTooltip,
    BDropdown,
    BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      sortDesc: true,
      selectedButton: "status",
      waiting_calls: new Map(),
      total_finish_code_counts: 0,
      wc_arr: [],
      queues: [],
      total_waiting_in_queue: 0,
      queue_waiting_count_interval: "",
      chartjsData: {
        data: {
          //labels: ['Uygun', 'Mola', 'Çağrıda (Giden)', 'Çağrıda (Gelen)', 'Çalıyor', 'Çağrı Sonrası'],
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: "#7367F0",
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function (tooltipItem, data) {
                return toHHMMSS(tooltipItem.yLabel);
              },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: "#6e6b7b",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                // tickAmount: 2,

                ticks: {
                  stepSize: 1800,
                  min: 0,
                  fontColor: "#6e6b7b",
                  callback: function (value, index, ticks) {
                    return toHHMMSS(value);
                  },
                },
              },
            ],
          },
        },
      },
      statistics: {
        inbound_success: 0,
        inbound_failed: 0,
        inbound_total: 0,
        inbound_avg_time: "00:00:00",
        inbound_total_time: "00:00:00",
        outbound_success: 0,
        outbound_failed: 0,
        outbound_total: 0,
        outbound_avg_time: "00:00:00",
        outbound_total_time: "00:00:00",
        productivity: 0,
        actions: [],
        status: [],
        break_time: 0,
        remaining_break_time: 0,
        breaks: [
          { reason: "Break", count: 0 },
          { reason: "Lunch", count: 0 },
        ],
        total_finish_code: "0",
        total_break_time: 0,
      },
      monthly_chart_info: {},
      collection_statistics: {},
      statisticsItems: [
        {
          icon: "ThumbsUpIcon",
          color: "light-success",
          title: "230",
          subtitle: globalThis._lang("t_answered"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "CheckIcon",
          color: "light-success",
          title: "827",
          subtitle: globalThis._lang("t_reachedCalls"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "ThumbsDownIcon",
          color: "light-danger",
          title: "13",
          subtitle: globalThis._lang("t_unreachedCalls"),
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "XIcon",
          color: "light-danger",
          title: "121",
          subtitle: globalThis._lang("t_missedCalls"),
          customClass: "",
        },
      ],

      status_fields: [
        { key: "statu", label: globalThis._lang("t_status"), sortable: true },
        { key: "time", label: globalThis._lang("t_duration"), sortable: true },
      ],
      wq_fields: [
        { key: "queue", label: globalThis._lang("t_queue"), sortable: true },
        {
          key: "call_count",
          label: globalThis._lang("t_count"),
          sortable: true,
        },
      ],
      fc_fields: [
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        { key: "count", label: globalThis._lang("t_count"), sortable: true },
        {
          key: "rate",
          label: globalThis._lang("t_rate").replace(/ /g, "\u00a0"),
          sortable: true,
        },
        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      st_data: [],
      st_fields: [
        {
          key: "display_name",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        { key: "time", label: globalThis._lang("t_time"), sortable: true },

        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      ch_fields: [
        {
          key: "insert_date",
          label: globalThis._lang("t_hour"),
          sortable: true,
        },
        {
          key: "contact_number",
          label: globalThis._lang("t_number"),
          sortable: true,
        },
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        {
          key: "queuename",
          label: globalThis._lang("t_queue"),
          sortable: true,
        },
        {
          key: "call_direction",
          label: globalThis._lang("t_direction"),
          sortable: true,
        },
        { key: "note", label: globalThis._lang("t_note"), sortable: false },
        {
          key: "RecordingFile",
          label: globalThis._lang("t_recordingFile"),
          sortable: false,
        },
      ],
      fc_category_colors: {
        Başarılı: "success",
        Başarısız: "warning",
        Randevu: "primary",
        Other: "secondary",
      },
      fc_data: [
        // {
        //   finish_code: 'Cevapsız',
        //   count: '36',
        //   rate: 36,
        //   category: 'Başarısız',
        // },
        // {
        //   finish_code: 'Ödeme Sözü',
        //   count: '11',
        //   rate: 11,
        //   category: 'Başarılı',
        // },
        // {
        //   finish_code: 'Ödeme Red',
        //   count: '16',
        //   rate: 16,
        //   category: 'Başarısız',
        // },
        // {
        //   finish_code: 'Müsait Değil',
        //   count: '4',
        //   rate: 4,
        //   category: 'Randevu',
        // },
        // {
        //   finish_code: 'Other',
        //   count: '37',
        //   rate: 37,
        //   category: 'Other',
        // },
      ],
      ch_data: [
        // {
        //   insert_date: '12:57:11',
        //   contact_number: '05526350190',
        //   finish_code: 'Ödeme Sözü',
        //   queuename: 'Voyce Queue',
        //   call_direction: 'outbound',
        //   note: 'Burak Ersever',
        // },
        // {
        //   insert_date: '12:57:11',
        //   contact_number: '05526350190',
        //   finish_code: 'Ödeme Sözü',
        //   queuename: 'Voyce Queue',
        //   call_direction: 'outbound',
        //   note: 'Burak Ersever',
        // },
        // {
        //   insert_date: '12:57:11',
        //   contact_number: '05526350190',
        //   finish_code: 'Ödeme Sözü',
        //   queuename: 'Voyce Queue',
        //   call_direction: 'outbound',
        //   note: 'Burak Ersever',
        // },
        // {
        //   insert_date: '12:57:11',
        //   contact_number: '05526350190',
        //   finish_code: 'Ödeme Sözü',
        //   queuename: 'Voyce Queue',
        //   call_direction: 'outbound',
        //   note: 'Burak Ersever',
        // },
        // {
        //   insert_date: '12:57:11',
        //   contact_number: '05526350190',
        //   finish_code: 'Ödeme Sözü',
        //   queuename: 'Voyce Queue',
        //   call_direction: 'outbound',
        //   note: 'Burak Ersever',
        // },
      ],
      waiting_on_queue_data: [
        // {
        //   queue: 'Garanti Bucket 1', call_count: 1
        // },
        // {
        //   queue: 'Yapıkredi', call_count: 4
        // },
        // {
        //   queue: 'Digiturk', call_count: 7
        // },
      ],
      revenueComparisonLine: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ["#d0ccff", $themeColors.secondary],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary, "#ebe9f1"],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ["01", "05", "09", "13", "17", "21", "26", "31"],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          grid: {
            borderColor: "#e7eef7",
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      revenue: {},
      interaction_queues: {},
      queues: [],
      custom_key: 0,
      selected_audio: "",
    };
  },
  methods: {
    GetRemoteNumber(phone) {
      if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
        return globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
      } else {
        return phone.split(' ').join('').replace(/\D+/g, '');
      }
    },
    toHHMMSS(sec_num) {
      try {
        return new Date(sec_num * 1000).toISOString().substr(11, 8);
      } catch (error) {
        return sec_num;
      }
    },
    set_waiting_in_queue_color(count) {
      if (Number(count) == 0) {
        return 'light-secondary'
      }
      else if (Number(count) == 1) {
        return 'light-primary'
      }
      else if (Number(count) == 2) {
        return 'light-warning'
      }
      else if (Number(count) > 2) {
        return 'light-danger'
      }

    },
    openAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }
      this.htmlcontent = `<audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("agent_download_sound")
        ? ""
        : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
                <source src="${this.selected_audio}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>`;
      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.finish_code}</span><br><span class="font-weight-bolder">  ${item.contact_number}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    get_ch: async function () {
      var response = (
        await this.$http_in.get(
          `agent/v1/CallHistory/${globalThis.user.username}`
        )
      ).data;

      this.ch_data = [];
      for (const row of response) {
        this.ch_data.push({
          insert_date: new Date(row.StartTime + 1000 * 60 * 3 * 60)
            .toISOString()
            .substr(11, 8),
          contact_number: row.RemoteNumber.substr(-10),
          finish_code: row.Verdict,
          queuename:
            row.Direction == "outbound" ? row.OutboundQueue : row.Queue,
          call_direction: row.Direction,
          note: row.FinishCodeDetails.note || "",
          RecordingFile: globalThis.v_navbar.other_permission.includes(
            "agent_display_sound"
          )
            ? row.RecordingFile
            : "",
        });
      }
      // console.log("CH", this.ch_data);
    },
    start_call(phone) {
      globalThis.LayoutContentRendererDefault.preview_call_request(phone);
    },
    get_live_agent: async function () {

      var response = (
        await this.$http_in.get(`qmgr/v1/LiveAgent/${globalThis.user.username}`)
      ).data;

      // console.log("get_live_agent", response);

      try {
        // STATS
        this.statistics.productivity = response?.Productivity?.calculated ?? 0;
        this.statistics.inbound_success = response.AcceptedCalls ?? 0;
        this.statistics.inbound_failed = response.MissedCalls ?? 0;
        this.statistics.outbound_success = ((response.SuccessfulOutboundCalls ?? 0) + (response.AcceptedDialerCalls ?? 0));
        this.statistics.outbound_failed = response.FailedOutboundcalls ?? 0;
        this.statistics.inbound_avg_time = toHHMMSS(
          (response.ReasonLengths["In Call - Inbound"] ?? 0) /
          (response.AcceptedCalls ?? 0) +
          (response.MissedCalls ?? 0)
        ) ?? 0;
        this.statistics.outbound_avg_time =
          toHHMMSS(
            (response.ReasonLengths["In Call - Outbound"] ?? 0) /
            (response.SuccessfulOutboundCalls ?? 0) +
            (response.FailedOutboundcalls ?? 0)
          ) ?? 0;
      } catch (error) {
        console.log(error);
      }

      try {
        // FINISH CODES
        this.fc_data = [];
        this.total_finish_code_counts = 0;
        for (const item of Object.keys(response.FinishCodeCounts)) {
          this.total_finish_code_counts += response.FinishCodeCounts[item];
        }

        for (const item of Object.keys(response.FinishCodeCounts)) {
          // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
          let _fc = globalThis.finish_codes.find((j) => j.finish_code == item);
          if (_fc && !_fc.show_in_dashboard) {
            let _other = this.fc_data.find((e) => e.finish_code == "Other");
            if (_other != undefined) {
              _other.count += response.FinishCodeCounts[item];
              _other.rate += response.FinishCodeCounts[item];
            } else {
              this.fc_data.push({
                finish_code: "Other",
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_fc) {
              this.fc_data.push({
                finish_code: item,
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis.finish_categories.find(
                  (e) => e._id == _fc.category_id
                ).category,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);

      }
      try {
        // STATUS
        this.statistics.break_time = 0;
        this.st_data = [];
        for (const item of Object.keys(response.ReasonLengths)) {
          if (['Offline', ''].includes(item)) {
            continue;
          }
          // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
          let _st = globalThis.statuses.find((j) => j.display_name == item);
          if (_st &&
            globalThis.status_categories.find(
              (e) => e._id == _st.status_category_id
            ).internal_name == "break"
          ) {
            this.statistics.break_time += response.ReasonLengths[item];
          }
          if (_st && !_st.show_in_dashboard) {
            let _other = this.st_data.find((e) => e.display_name == "Other");
            if (_other != undefined) {
              _other.time += response.ReasonLengths[item];
            } else {
              this.st_data.push({
                display_name: "Other",
                time: response.ReasonLengths[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_st) {
              this.st_data.push({
                display_name: item,
                time: response.ReasonLengths[item],
                category: globalThis.status_categories.find(
                  (e) => e._id == _st.status_category_id
                ).display_name,
              });
            }
          }
        }

        // this.chartjsData.data.labels = [];
        // this.chartjsData.data.datasets[0].data = [];
        // let cols = [];
        // let vals = [];
        // this.statistics.break_time = 0;
        // for (const item of Object.keys(response.ReasonLengths)) {
        //   if (item == "") {
        //     continue;
        //   }
        //   let _statu = globalThis.statuses.find((e) => e.display_name == item);
        //   if (_statu && _statu.show_in_dashboard) {
        //     if (
        //       globalThis.status_categories.find(
        //         (e) => e._id == _statu.status_category_id
        //       ).internal_name == "break"
        //     ) {
        //       this.statistics.break_time += response.ReasonLengths[item];
        //     }
        //     if (item.includes(' - ')) {
        //       cols.push(item.split(' - ')[1]);
        //     }
        //     else {
        //       cols.push(item);
        //     }
        //     vals.push(response.ReasonLengths[item]);
        //   }
        // }

        // console.log("this.statistics.total_break_time", this.statistics.total_break_time);
        // console.log("this.statistics.break_time", this.statistics.break_time);
        if (this.statistics.total_break_time <= this.statistics.break_time) {
          this.statistics.remaining_break_time = toHHMMSS(0);
          this.statistics.break_time = toHHMMSS(this.statistics.break_time);
        } else {
          this.statistics.remaining_break_time = toHHMMSS(
            this.statistics.total_break_time - this.statistics.break_time
          );
          this.statistics.break_time = toHHMMSS(this.statistics.break_time);
        }

        // this.chartjsData.data = {
        //   ...this.chartjsData.data,
        //   datasets: [
        //     {
        //       data: [...vals],
        //       backgroundColor: "#7367F0",
        //       borderColor: "transparent",
        //     },
        //   ],
        //   labels: [...cols],
        // };

        // this.custom_key = Math.floor(Math.random() * 101);
      } catch (error) {
        console.log(error);

      }

    },
    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`agent/v1/Queue`)).data;
      this.queues = [];
      this.queues.push({
        internal_name: "undefined",
        display_name: "Undefined",
      });

      this.queues = [...this.queues, ...response];
      //
    },
    GetCollectionStatistics: async function (_date) {
      var response = (
        await this.$http_in.get(`agent/v1/CollectionStatistics/${_date}`)
      ).data;

      // console.log("response.bar_info[0]",response.bar_info[0]);
      if (response.bar_info == undefined) {
        return;
      }
      this.collection_statistics = {
        ...response.bar_info,
      };

      let date = new Date(_date);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var _bussiness_days = this.businessDays(firstDay, lastDay);
      var bussiness_days = _bussiness_days.length;
      var daily_goals = [];
      var daily_goals_columns = [];
      // console.log("_bussiness_days",_bussiness_days);
      for (let index = 0; index < bussiness_days; index++) {
        // daily_goals_columns.push(parseInt(index) + 1);
        daily_goals.push(
          parseInt(
            ((parseInt(index) + 1) *
              parseInt(this.collection_statistics.collection_goal)) /
            bussiness_days
          )
        );
      }

      var _array = [];
      for (const item of _bussiness_days) {
        this.monthly_chart_info[item] = 0;
        // console.log("item",item);
        for (const item3 of response.monthly_chart_info) {
          // console.log("item3",item3);

          if (new Date(item3.collection_date).getDate() <= parseInt(item)) {
            // console.log("item33",parseInt(item3.collection_payment));

            this.monthly_chart_info[item] += parseInt(item3.collection_payment);
          }
        }
      }
      // console.log("this.monthly_chart_info",this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      // console.log("daily_goals", daily_goals);
      // console.log("_bussiness_days", _bussiness_days);
      // console.log("this.revenueComparisonLine", this.revenueComparisonLine);

      let _co = this.revenueComparisonLine.chartOptions;
      _co.xaxis.categories = _bussiness_days;

      this.revenueComparisonLine.chartOptions = {
        ..._co,
      };
      // this.revenueComparisonLine.chartOptions.xaxis.categories = _bussiness_days;
      this.revenueComparisonLine.series[0].data = _tmp;
      this.revenueComparisonLine.series[1].data = daily_goals;
    },
    businessDays(date1, date2) {
      var days = ["N", "Y", "Y", "Y", "Y", "Y", "N"];

      var d1 = new Date(date1);
      var d2 = new Date(date2);

      var isGunu = 0;
      var _days = [];
      while (true) {
        if (d1 > d2) {
          break;
        }

        var dayName = days[d1.getDay()];

        if (dayName != "N") {
          isGunu++;
          _days.push(d1.getDate());
        }

        d1.setDate(d1.getDate() + 1);
      }
      return _days;
    },
  },
  mounted: async function () {
    globalThis.statisticts = this;

    // if (globalThis.projects.length == 0) {
    //   var getproject_interval = setInterval(async ()=>{
    //     if(globalThis.v_navbar && globalThis.v_navbar.GetProjects){
    //       clearInterval(getproject_interval);
    //       await globalThis.v_navbar.GetProjects();
    //     }
    //   }, 500)
    // }

    globalThis.qm_status_watcher.on("connected", () => {
      this.get_live_agent();
    });
    this.get_ch();

    let _self = this;
    this.get_queues();
    // this.queues = globalThis.queues;
    let date = new Date();

    let month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    this.GetCollectionStatistics(`${date.getFullYear()}-${month}`);

    setInterval(() => {
      _self.waiting_on_queue_data = [];

      for (const item of Object.keys(window.v_navbar.interaction_queues)) {
        _self.waiting_on_queue_data.push({
          queue: item,
          call_count: window.v_navbar.interaction_queues[item].size,
        });
      }
      _self.waiting_on_queue_data = [..._self.waiting_on_queue_data];
    }, 2000);

    this.statistics.total_break_time = globalThis.projects.find(
      (e) => e.internal_name == globalThis.user.selected_project
    ).break_time;
    if (_voyce.$qm && _voyce.$qm.connected) {
      this.get_live_agent();
    }
  },
  beforeDestroy: function () {
    if (this.queue_waiting_count_interval != "") {
      clearInterval(this.queue_waiting_count_interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.card-body {
  position: relative;
  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
.large-2 {
  margin-left: 30px;
  float: left;
  height: 300px;
  overflow-y: scroll;
  width: 100px;
  background: #ccc;
}

.force-overflow {
  min-height: 450px;
}

.large-2::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #a8a8a8;
}

.large-2::-webkit-scrollbar {
  width: 10px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a8a8a8;
}
</style>
